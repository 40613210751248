/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import "../styles.scss";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { RestockReportsTable, StockoutReportsTable } from "./components";

const GeneratedReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});
  const [values, setValues] = useState<any>({ filter: "" });

  useEffect(() => {
    const paramsObj: Record<string, string> = {};
    params.forEach((value, key) => {
      paramsObj[key] = value;
    });
    setQueryParams(paramsObj);
    setValues({ ...values, filter: paramsObj?.type });
  }, [dispatch, params]);

  return (
    <div className="w-full h-full">
      <div className="flex w-full gap-x-4">
        <Breadcrumb
          names={["Report", queryParams?.name]}
          isPrevious={true}
          showBackButton={true}
        />
      </div>
      <div className="default-page-height w-full xl:pt-10 pt-5 flex justify-center overflow-y-auto">
        <div className="xl:w-11/12 w-full">
          <p className="text-x-sm-headline font-medium">{queryParams?.name}</p>
          {queryParams?.type === "1" ? <RestockReportsTable /> : <></>}
          {queryParams?.type === "2" ? <StockoutReportsTable /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default GeneratedReport;
