import React, { FC, useEffect, useState } from "react";
import "../../styles.scss";
import { Table } from "antd";
import TableActions from "../../../../components/TableActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { AppDispatch } from "../../../../redux/store";
import { FetchStockoutReports } from "../../../../redux/actions/reports.action";
import { ApiPaginationModel } from "../../../../model/base.model";
import { itemRender, paginationFooter } from "../../../../utils/constants";

const StockoutReportsTable: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState<ApiPaginationModel>({
    itemsPerPage: 10,
    page: 1,
  });
  const isLoading = useSelector(
    (state: RootState) => state.reports.isReportsActionLoading
  );
  const reports = useSelector(
    (state: RootState) => state.reports.stockoutReports
  );

  useEffect(() => {
    dispatch(FetchStockoutReports(paginationData));
  }, [dispatch, paginationData]);

  const columns = [
    {
      title: "Location name",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "Current Stock",
      dataIndex: "current_stock",
      key: "current_stock",
    },
    {
      title: "Restock Threshold",
      dataIndex: "restock_threshold",
      key: "restock_threshold",
    },
  ];

  const data =
    reports &&
    reports.items &&
    reports.items?.length > 0 &&
    reports.items?.map((report, index) => {
      return {
        key: index,
        location_name: report.location_name ?? "---",
        product_name: report?.product_name ?? "---",
        upc: report?.upc ?? "---",
        current_stock: report?.current_stock ?? "---",
        restock_threshold: report?.restock_threshold ?? "---",
        report,
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current: number, pageSize: number) => {
    setPaginationData({
      ...paginationData,
      itemsPerPage: pageSize,
      page: current,
    });
  };

  const handleExport = () => {};

  return (
    <>
      <TableActions label={" "} handleExport={handleExport} />
      <div className="w-full bg-white report-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: reports?.total_count,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              paginationFooter(paginationData, reports?.total_count, total),
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                reports?.total_count,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { StockoutReportsTable };
