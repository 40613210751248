import React, { FC, useState } from "react";
import "../styles.scss";
import { IDateRangeFilter } from "../model";
import { DateRange, Range } from "react-date-range";
import { localISOTime } from "../../utils/constants";
import { Modal } from "antd";
import Button from "../Button";
import { ButtonState } from "../enum";

const DateRangeFilter: FC<IDateRangeFilter> = ({
  closeFilter,
  openFilter = false,
  setDateRange,
}) => {
  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#2763E9",
    },
  ]);

  const handleSetDate = () => {
    if (state[0].startDate !== null && state[0].endDate !== null)
      setDateRange({
        start_date: localISOTime(state[0].startDate),
        end_date: localISOTime(state[0].endDate),
      });
    closeFilter();
  };

  return (
    <Modal
      open={openFilter}
      closable={false}
      footer={null}
      onCancel={closeFilter}
      className={"filter-modal"}
    >
      <DateRange
        onChange={(item) => setState([item.selection])}
        showPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
      />
      <Button
        css={"w-full !rounded-[6px]"}
        state={ButtonState.PRIMARY}
        text={"Continue"}
        onClick={handleSetDate}
        type={"button"}
      />
    </Modal>
  );
};

export default DateRangeFilter;
