import { UserItemModel } from "../../model/user.model";
import { ReportState } from "../../views/Main/enum";
import {
  IReport,
  IReportRandomColor,
  IReportType,
  IUserLog,
} from "../../views/Main/model";

export const USERS: UserItemModel[] = [
  {
    id: "1",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "2",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "3",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "4",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "5",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "6",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "7",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "8",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "9",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "10",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "11",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "12",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "13",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "14",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "15",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "16",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "17",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "18",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "19",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "20",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "21",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
  {
    id: "22",
    first_name: "Petra",
    last_name: "Laylay",
    staff_id: "#23455666",
    phonenumber: "08103035840",
    status: "Active",
    role: "User",
  },
];

export const USERS_LOGS: IUserLog[] = [
  {
    id: 1,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 2,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 3,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 4,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 5,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 6,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 7,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 8,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 9,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 10,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 11,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 12,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 13,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 14,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 15,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 16,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 17,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 18,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 19,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
  {
    id: 20,
    name: "Petra of laylay",
    staffId: "#23455666",
    action: "Moved from ocation A TO b",
    activityType: "Act A",
    role: "Role 1",
    time: "09:12 AM",
    date: "12/20/29",
  },
];

export const REPORT_TYPES: IReportType[] = [
  { id: 1, name: "All" },
  { id: 2, name: "Business Overview" },
  { id: 3, name: "Sales" },
  { id: 4, name: "Receivables" },
  { id: 5, name: "User logs" },
];

export const REPORTS: IReport[] = [
  { id: 1, name: "Restock report", type: 1 },
  { id: 2, name: "Stockout report", type: 2 },
  { id: 3, name: "Stock value report", type: 3 },
  { id: 4, name: "Gross profit report", type: 4 },
];

export const REPORT_STATES: IReportRandomColor[] = [
  {
    state: ReportState.BLUE,
    color: "blue-evelope",
    bg: "h-[42px] w-[42px] rounded-full bg-[#EDF3FF] flex items-center justify-center",
  },
  {
    state: ReportState.PINK,
    color: "pink-evelope",
    bg: "h-[42px] w-[42px] rounded-full bg-[#FFEDFD] flex items-center justify-center",
  },
  {
    state: ReportState.ORANGE,
    color: "orange-evelope",
    bg: "h-[42px] w-[42px] rounded-full bg-[#FFF2EE] flex items-center justify-center",
  },
  {
    state: ReportState.GREEN,
    color: "green-evelope",
    bg: "h-[42px] w-[42px] rounded-full bg-[#EDFFF3] flex items-center justify-center",
  },
];
