export enum Currency {
  USD = "USD",
  CAD = "CAD",
  GBP = "GBP",
  EUR = "EUR",
  CNY = "CNY",
  NGN = "NGN",
}

export enum ReportState {
  BLUE = "BLUE",
  PINK = "PINK",
  GREEN = "GREEN",
  ORANGE = "ORANGE",
}
