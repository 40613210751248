import { combineReducers } from "redux";
import { authReducer } from "./auths.reducer";
import { locationReducer } from "./locations.reducer";
import { supplierReducer } from "./suppliers.reducer";
import { productReducer } from "./products.reducer";
import { poReducer } from "./pos.reducer";
import { alertsReducer } from "./alerts.reducer";
import { userReducer } from "./users.reducer";
import { reportsReducer } from "./reports.reducer";

const rootReducer = combineReducers({
  auths: authReducer,
  locations: locationReducer,
  suppliers: supplierReducer,
  products: productReducer,
  pos: poReducer,
  alerts: alertsReducer,
  users: userReducer,
  reports: reportsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
