import React, { useEffect, useState } from "react";
import "../styles.scss";
import PageHeader from "../../../components/PageHeader";
import { LeftHeaderType } from "../../../components/enum";
import TableActions from "../../../components/TableActions";
import { IReport, IReportRandomColor, IReportType } from "../model";
import {
  REPORT_STATES,
  REPORT_TYPES,
  REPORTS,
} from "../../../utils/misc/database";
import { ReactComponent as QuicstockChevronDown } from "../../../assets/svgs/quicstock-envelope.svg";
import AppDrawer from "../../../components/AppDrawer";
import {
  GrossProfitReportPreference,
  StockValueReportPreference,
} from "./components";
import { useNavigate } from "react-router-dom";

const ACTIVE_TYPE =
  "rounded-[8px] border-[1px] p-[12px] text-labels bg-white cursor-pointer text-black transition-all duration-100 ease-in-out";
const INACTIVE_TYPE =
  "p-[12px] text-labels cursor-pointer text-gray-300 transition-all duration-100 ease-in-out";

const Reports = () => {
  const navigate = useNavigate();
  const [openReportPreferenceDrawer, setOpenReportPreferenceDrawer] =
    useState(false);
  const [search, setSearch] = useState<string>("");
  const [type, setType] = useState<number>(1);
  const reportTypes: IReportType[] = REPORT_TYPES;
  const [reports, setReports] = useState<IReport[]>(REPORTS);
  const reportStates: IReportRandomColor[] = REPORT_STATES;
  const [report, setReport] = useState<IReport>();

  useEffect(() => {
    console.log(search);
  }, [search]);

  const getRandomNumber = (): number => {
    return Math.floor(Math.random() * 4);
  };

  const handleSetType = (id: number) => {
    setType(id);
    if (id === 1) {
      setReports([...REPORTS]);
    } else {
      const reps: IReport[] = REPORTS.filter((r) => r.type === id);
      setReports([...reps]);
    }
  };

  const toggleReportPreferenceDrawer = () => {
    setOpenReportPreferenceDrawer(!openReportPreferenceDrawer);
  };

  const handleGenerateReport = (filters: any) => {
    const params = new URLSearchParams({
      name: report?.name,
      type: report?.type,
      ...filters,
    }).toString();
    navigate(`/dashboard/reports/generate-with-filters?${params}`);
    toggleReportPreferenceDrawer();
  };

  const handleReport = (rr: IReport) => {
    if (rr.type === 1 || rr.type === 2) {
      const params = new URLSearchParams({
        type: rr.type.toString(),
        name: rr.name,
      }).toString();
      navigate(`/dashboard/reports/generate?${params}`);
    } else {
      setReport(rr);
      toggleReportPreferenceDrawer();
    }
  };

  return (
    <div className="w-full h-full">
      <PageHeader
        leftHeaderType={LeftHeaderType.CUSTOM}
        showSearchField={true}
        title={"Report"}
        subtitle={"Your report"}
        placeholder={"Search"}
        getSearchValue={setSearch}
      />
      <div className="default-page-height w-full">
        <TableActions label={"Actions you can perform"} />
        <div className="w-full grid grid-cols-12 gap-x-4 py-6 h-full">
          <div className="col-span-3 h-full">
            <div className="w-full bg-dark-50 rounded-[10px] p-[12px] border-[1px] border-dark-900 h-full gap-y-2 flex flex-col">
              {reportTypes.map((rt) => (
                <div
                  key={rt.name}
                  className={type === rt.id ? ACTIVE_TYPE : INACTIVE_TYPE}
                  onClick={() => handleSetType(rt.id)}
                >
                  {rt.name}
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-9">
            <div className="w-full h-full">
              <div className="grid grid-cols-2 gap-4">
                {reports?.map((rr, index) => {
                  const randomIndex = getRandomNumber();
                  return (
                    <React.Fragment key={rr.name + rr.id}>
                      <div className="col">
                        <div className="w-full rounded-[8px] border-[1px] border-[#EDEDED] p-[16px] text-sm-regular flex justify-between items-center">
                          <div className="flex items-center gap-x-2">
                            <div
                              className={`${reportStates[randomIndex].bg} ${reportStates[randomIndex].color}`}
                            >
                              <QuicstockChevronDown />
                            </div>
                            <div>{rr.name}</div>
                          </div>
                          <button
                            onClick={() => handleReport(rr)}
                            className="text-labels w-[75px] h-[32px] border-[1px] rounded-[6px] border-[#E0E0E0] text-gray-300"
                          >
                            {rr?.type === 1 || rr?.type === 2
                              ? "Generate"
                              : "Filter"}
                          </button>
                        </div>
                      </div>
                      {(index + 1) % 2 === 0 ? (
                        <div className="col-span-2 border-t-[1px] border-dark-900"></div>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Report preference */}
      <AppDrawer
        open={openReportPreferenceDrawer}
        toggle={toggleReportPreferenceDrawer}
        title={"Set Your Report Preferences"}
        subtitle={
          "Choose how you’d like the report to be generated. Don’t worry,you can always make changes later on the report page"
        }
      >
        {report?.type === 3 ? (
          <StockValueReportPreference
            cancel={toggleReportPreferenceDrawer}
            generate={handleGenerateReport}
          />
        ) : (
          <></>
        )}
        {report?.type === 4 ? (
          <GrossProfitReportPreference
            cancel={toggleReportPreferenceDrawer}
            generate={handleGenerateReport}
          />
        ) : (
          <></>
        )}
      </AppDrawer>
      {/* Report preference */}
    </div>
  );
};

export default Reports;
