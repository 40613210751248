/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react";
import "../../styles.scss";
import { IGrossProfitReportPreference } from "../../model";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { AutoComplete, Form, Select, Spin } from "antd";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import FieldName from "../../../../components/FieldName";
import DateRangeFilter from "../../../../components/DateRangeFilter";
import { IDateRange } from "../../../../components/model";
import {
  lastMonth,
  lastWeek,
  lastQuarter,
  lastYear,
  thisMonth,
  thisWeek,
  thisQuarter,
  thisYear,
  today,
  yesterday,
  formatDate,
} from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { ApiDropdownModel } from "../../../../model/base.model";
import { debounce } from "lodash";
import { SearchProducts } from "../../../../redux/actions/products.action";
import { AppDispatch } from "../../../../redux/store";

const GrossProfitReportPreference: FC<IGrossProfitReportPreference> = ({
  cancel,
  generate,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const values = { filter: "", product_id: "" };
  const [form] = Form.useForm();
  const [selectedProductName, setSelectedProductName] = useState("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const productSearchResult = useSelector(
    (state: RootState) => state.products.productSearchResult
  );
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<IDateRange>({
    start_date: "",
    end_date: "",
  });

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    generate({ ...values, ...dateRange });
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const debouncedSearch = debounce(
    async (value, setOptions, dispatch, setSelectedProductName) => {
      if (value) {
        dispatch(SearchProducts(value));
      } else {
        setOptions([]);
        setSelectedProductName("");
      }
    },
    300
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value, setOptions, dispatch, setSelectedProductName);
    },
    [setOptions, dispatch, setSelectedProductName]
  );

  useEffect(() => {
    if (productSearchResult && productSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = productSearchResult.map(
        (product) => ({
          value: product.id,
          label: product.name,
          name: product.name,
        })
      );
      setOptions(suggestions);
    }
  }, [productSearchResult]);

  const handleFilter = (value: string) => {
    switch (value) {
      case "TODAY":
        setDateRange(today());
        break;
      case "THIS_WEEK":
        setDateRange(thisWeek());
        break;
      case "THIS_MONTH":
        setDateRange(thisMonth());
        break;
      case "THIS_QUARTER":
        setDateRange(thisQuarter());
        break;
      case "THIS_YEAR":
        setDateRange(thisYear());
        break;
      case "YESTERDAY":
        setDateRange(yesterday());
        break;
      case "LAST_WEEK":
        setDateRange(lastWeek());
        break;
      case "LAST_MONTH":
        setDateRange(lastMonth());
        break;
      case "LAST_QUARTER":
        setDateRange(lastQuarter());
        break;
      case "LAST_YEAR":
        setDateRange(lastYear());
        break;
      case "CUSTOM":
        toggleFilter();
        break;
      default:
        return;
    }
  };

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit, handleBlur }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={`w-full drawer-content-height-no-step`}>
            <div className={"text-left text-sm-regular my-6 font-medium"}>
              Profit and loss report
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Preferred date range"} required />
                <Form.Item
                  name={"filter"}
                  help={touched.filter && errors.filter ? errors.filter : ""}
                  validateStatus={
                    touched.filter && errors.filter ? "error" : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Select
                        placeholder={"Select preferred date range"}
                        value={values?.filter || null}
                        onBlur={handleBlur}
                        className={"form-field_input_3"}
                        onChange={(e: any) => {
                          setFieldValue("filter", e);
                        }}
                        onSelect={(e: any) => {
                          handleFilter(e);
                        }}
                        options={[
                          { value: "TODAY", label: "This day" },
                          { value: "THIS_WEEK", label: "This week" },
                          { value: "THIS_MONTH", label: "This month" },
                          { value: "THIS_QUARTER", label: "This quarter" },
                          { value: "THIS_YEAR", label: "Year to date" },
                          { value: "YESTERDAY", label: "Yesterday" },
                          { value: "LAST_WEEK", label: "Previous week" },
                          { value: "LAST_MONTH", label: "Previous month" },
                          { value: "LAST_QUARTER", label: "Previous quarter" },
                          { value: "LAST_YEAR", label: "Previous year" },
                          { value: "CUSTOM", label: "Custom date range" },
                        ]}
                        dropdownRender={(menu) => (
                          <div style={{ textAlign: "center" }}>{menu}</div>
                        )}
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            {dateRange.start_date && dateRange.end_date ? (
              <div className="w-full bg-dark-50 rounded-[10px] p-[12px] border-[1px] border-dark-900 flex justify-between mb-5">
                <p className="text-black">
                  <span className="font-medium">Start:</span>{" "}
                  {formatDate(dateRange.start_date)}
                </p>
                <p className="text-black">
                  <span className="font-medium">End:</span>{" "}
                  {formatDate(dateRange.end_date)}
                </p>
              </div>
            ) : (
              <></>
            )}
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Product"} />
                <Form.Item
                  name={"product_id"}
                  help={
                    touched.product_id && errors.product_id
                      ? errors.product_id
                      : ""
                  }
                  validateStatus={
                    touched.product_id && errors.product_id
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <AutoComplete
                        options={options}
                        onSearch={handleSearch}
                        onSelect={(value, option) => {
                          setFieldValue("product_id", value);
                          setSelectedProductName(option.name ?? "");
                        }}
                        onChange={(value) => {
                          setSelectedProductName(value);
                          setFieldValue("product_id", "");
                          if (!value) {
                            setOptions([]);
                          }
                        }}
                        value={selectedProductName || values.product_id}
                        notFoundContent={
                          isLoading ? <Spin size="small" /> : null
                        }
                        allowClear
                        style={{ width: "100%", height: "48px" }}
                      >
                        <input
                          type="text"
                          className="form-field_input_2"
                          placeholder="Search product"
                        />
                      </AutoComplete>
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Go back"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Generate Report"}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
          <DateRangeFilter
            closeFilter={toggleFilter}
            openFilter={openFilter}
            setDateRange={setDateRange}
          />
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  filter: yup.string().required("Preferred date range is required"),
});

export { GrossProfitReportPreference };
