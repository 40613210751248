import React, { useEffect, useMemo, useState } from "react";
import "../styles.scss";
import { useLocation } from "react-router-dom";
import { goBack } from "../../../utils/constants";
import Breadcrumb from "../../../components/Breadcrumb";
import { GrossProfitReportTable, StockValueReportTable } from "./components";

const FilteredReport = () => {
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});

  useEffect(() => {
    const paramsObj: Record<string, string> = {};
    params.forEach((value, key) => {
      paramsObj[key] = value;
    });

    if (Object.keys(paramsObj).length) {
      setQueryParams(paramsObj);
    } else {
      goBack();
    }
  }, [params]);

  return (
    <div className="w-full h-full">
      <div className="flex w-full gap-x-4">
        <Breadcrumb
          names={["Report", queryParams?.name]}
          isPrevious={true}
          showBackButton={true}
        />
      </div>
      <div className="default-page-height w-full xl:pt-10 pt-5 flex justify-center overflow-y-auto">
        <div className="xl:w-11/12 w-full">
          <p className="x-sm-headline font-medium">{queryParams?.name}</p>
          {queryParams?.type === "3" ? (
            <StockValueReportTable queryParams={queryParams} />
          ) : (
            <></>
          )}
          {queryParams?.type === "4" ? (
            <GrossProfitReportTable queryParams={queryParams} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilteredReport;
