import { ThunkAction } from "redux-thunk";
import {
  getRestockReports,
  getStockoutReports,
  getStockValueReports,
  getGrossProfitReports,
} from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import { ApiPaginationModel } from "../../model/base.model";

export const Types = {
  FETCH_RESTOCK_REPORTS_START: "FETCH_RESTOCK_REPORTS_START",
  FETCH_RESTOCK_REPORTS_SUCCESS: "FETCH_RESTOCK_REPORTS_SUCCESS",
  FETCH_RESTOCK_REPORTS_END: "FETCH_RESTOCK_REPORTS_END",

  FETCH_STOCKOUT_REPORTS_START: "FETCH_STOCKOUT_REPORTS_START",
  FETCH_STOCKOUT_REPORTS_SUCCESS: "FETCH_STOCKOUT_REPORTS_SUCCESS",
  FETCH_STOCKOUT_REPORTS_END: "FETCH_STOCKOUT_REPORTS_END",

  FETCH_STOCK_VALUE_REPORTS_START: "FETCH_STOCK_VALUE_REPORTS_START",
  FETCH_STOCK_VALUE_REPORTS_SUCCESS: "FETCH_STOCK_VALUE_REPORTS_SUCCESS",
  FETCH_STOCK_VALUE_REPORTS_END: "FETCH_STOCK_VALUE_REPORTS_END",

  FETCH_GROSS_PROFIT_REPORTS_START: "FETCH_GROSS_PROFIT_REPORTS_START",
  FETCH_GROSS_PROFIT_REPORTS_SUCCESS: "FETCH_GROSS_PROFIT_REPORTS_SUCCESS",
  FETCH_GROSS_PROFIT_REPORTS_END: "FETCH_GROSS_PROFIT_REPORTS_END",
};

export const FetchRestockReports = (
  params: ApiPaginationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_RESTOCK_REPORTS_START });
    return new Promise((resolve, reject) => {
      getRestockReports(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_RESTOCK_REPORTS_SUCCESS,
            restockReports: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_RESTOCK_REPORTS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchStockoutReports = (
  params: ApiPaginationModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_STOCKOUT_REPORTS_START });
    return new Promise((resolve, reject) => {
      getStockoutReports(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_STOCKOUT_REPORTS_SUCCESS,
            stockoutReports: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_STOCKOUT_REPORTS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchStockValueReports = (
  params: any
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_STOCK_VALUE_REPORTS_START });
    return new Promise((resolve, reject) => {
      getStockValueReports(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_STOCK_VALUE_REPORTS_SUCCESS,
            stockValueReports: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_STOCK_VALUE_REPORTS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};

export const FetchGrossProfitReports = (
  params: any
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.FETCH_GROSS_PROFIT_REPORTS_START });
    return new Promise((resolve, reject) => {
      getGrossProfitReports(params)
        .then(({ data }) => {
          dispatch({
            type: Types.FETCH_GROSS_PROFIT_REPORTS_SUCCESS,
            grossProfitReports: data.data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.FETCH_GROSS_PROFIT_REPORTS_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};
