import {
  GrossProfitReportsResponseItemModel,
  RestockReportsResponseItemModel,
  StockoutReportsResponseItemModel,
  StockValueReportsResponseItemModel,
} from "../../model/reports.model";
import { Types } from "../actions/reports.action";

interface ReportsState {
  isReportsActionLoading: boolean;
  message?: string;
  restockReports: RestockReportsResponseItemModel;
  stockoutReports: StockoutReportsResponseItemModel;
  stockValueReports: StockValueReportsResponseItemModel;
  grossProfitReports: GrossProfitReportsResponseItemModel;
}

interface ReportsAction {
  type: string;
  message?: string;
  restockReports: RestockReportsResponseItemModel;
  stockoutReports: StockoutReportsResponseItemModel;
  stockValueReports: StockValueReportsResponseItemModel;
  grossProfitReports: GrossProfitReportsResponseItemModel;
}

const INITIAL_STATE: ReportsState = {
  isReportsActionLoading: false,
  restockReports: null as any,
  stockoutReports: null as any,
  stockValueReports: null as any,
  grossProfitReports: null as any,
};

export const reportsReducer = (
  state = INITIAL_STATE,
  action: ReportsAction
) => {
  switch (action.type) {
    case Types.FETCH_RESTOCK_REPORTS_START:
    case Types.FETCH_STOCKOUT_REPORTS_START:
    case Types.FETCH_STOCK_VALUE_REPORTS_START:
    case Types.FETCH_GROSS_PROFIT_REPORTS_START:
      return { ...state, isReportsActionLoading: true };
    case Types.FETCH_RESTOCK_REPORTS_SUCCESS:
      return {
        ...state,
        isReportsActionLoading: false,
        restockReports: action.restockReports,
      };
    case Types.FETCH_STOCKOUT_REPORTS_SUCCESS:
      return {
        ...state,
        isReportsActionLoading: false,
        stockoutReports: action.stockoutReports,
      };
    case Types.FETCH_STOCK_VALUE_REPORTS_SUCCESS:
      return {
        ...state,
        isReportsActionLoading: false,
        stockValueReports: action.stockValueReports,
      };
    case Types.FETCH_GROSS_PROFIT_REPORTS_SUCCESS:
      return {
        ...state,
        isReportsActionLoading: false,
        grossProfitReports: action.grossProfitReports,
      };
    case Types.FETCH_RESTOCK_REPORTS_END:
    case Types.FETCH_STOCKOUT_REPORTS_END:
    case Types.FETCH_STOCK_VALUE_REPORTS_END:
    case Types.FETCH_GROSS_PROFIT_REPORTS_END:
      return {
        ...state,
        isReportsActionLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};
