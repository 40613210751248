/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react";
import "../../styles.scss";
import { IStockValueReportTable } from "../../model";
import { AutoComplete, Select, Spin, Table } from "antd";
import { ReactComponent as QuicstockUploadDoc } from "../../../../assets/svgs/quicstock-upload-doc.svg";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import {
  ApiDropdownModel,
  ApiPaginationModel,
} from "../../../../model/base.model";
import { RootState } from "../../../../redux/reducers";
import { debounce } from "lodash";
import {
  FetchProduct,
  SearchProducts,
} from "../../../../redux/actions/products.action";
import { FetchStockValueReports } from "../../../../redux/actions/reports.action";
import {
  formatCurrency,
  itemRender,
  paginationFooter,
} from "../../../../utils/constants";

const StockValueReportTable: FC<IStockValueReportTable> = ({ queryParams }) => {
  const dispatch: AppDispatch = useDispatch();
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [paginationData, setPaginationData] = useState<ApiPaginationModel>({
    itemsPerPage: 10,
    page: 1,
  });
  const [params, setParams] = useState<Record<string, string>>({});
  const [isNewSet, setIsNewSet] = useState<boolean>(false);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const isReportLoading = useSelector(
    (state: RootState) => state.reports.isReportsActionLoading
  );
  const reports = useSelector(
    (state: RootState) => state.reports.stockValueReports
  );
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const productSearchResult = useSelector(
    (state: RootState) => state.products.productSearchResult
  );

  useEffect(() => {
    if (queryParams && Object.keys(queryParams).length) {
      setParams(queryParams);
      const productName =
        productSearchResult.find((p) => p.id === queryParams.product_id)
          ?.name ?? "";
      if (!productName && queryParams.product_id) {
        fetchProduct(queryParams.product_id);
      } else if (productName) {
        setSelectedProductName(productName);
      }
      dispatch(FetchStockValueReports({ ...queryParams, ...paginationData }));
    }
  }, [queryParams]);

  const debouncedSearch = debounce(
    async (value, setOptions, dispatch, setSelectedProductName) => {
      if (value) {
        dispatch(SearchProducts(value));
      } else {
        setOptions([]);
        setSelectedProductName("");
      }
    },
    300
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value, setOptions, dispatch, setSelectedProductName);
    },
    [setOptions, dispatch, setSelectedProductName]
  );

  useEffect(() => {
    if (productSearchResult && productSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = productSearchResult.map(
        (product) => ({
          value: product.id,
          label: product.name,
          name: product.name,
        })
      );
      setOptions(suggestions);
    }
  }, [productSearchResult]);

  const fetchProduct = (product_id: string) => {
    dispatch(FetchProduct(product_id)).then((res) => {
      if (res && res.success) {
        setSelectedProductName(
          res?.data?.product_details?.default_properties?.name
        );
      }
    });
  };

  const handleFilter = (value: string, key: string) => {
    params[key] = value;
    setParams({ ...params });
    setIsNewSet(true);
  };

  const columns = [
    {
      title: "Location name",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
    },
    {
      title: "Total Value",
      dataIndex: "total_value",
      key: "total_value",
    },
  ];

  const data =
    reports &&
    reports.items &&
    reports.items?.length > 0 &&
    reports.items?.map((report, index) => {
      return {
        key: index,
        location_name: report.location_name ?? "---",
        product_name: report?.product_name ?? "---",
        quantity: report?.quantity ?? "---",
        unit_price: report?.unit_price
          ? formatCurrency(report.unit_price.toString())
          : "---",
        total_value: report?.total_value
          ? formatCurrency(report.total_value.toString())
          : "---",
        report,
      };
    });

  const onShowSizeChange = (current: number, pageSize: number) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current: number, pageSize: number) => {
    setPaginationData({
      ...paginationData,
      itemsPerPage: pageSize,
      page: current,
    });
    const q = { ...params };
    dispatch(FetchStockValueReports({ ...q, ...paginationData }));
  };

  const handleExport = () => {};

  useEffect(() => {
    setIsNewSet(false);
  }, [reports]);

  const handleApply = () => {
    setDefaultPageSize(10);
    setDefaultCurrent(1);
    setPaginationData({
      ...paginationData,
      itemsPerPage: 10,
      page: 1,
    });
    const q = { ...params };
    dispatch(
      FetchStockValueReports({ ...q, ...{ itemsPerPage: 10, page: 1 } })
    );
  };

  return (
    <>
      <div className="grid grid-cols-12 mt-5">
        <div className="flex items-center gap-x-4 col-span-10">
          <div className="grid grid-cols-12 gap-x-4 items-center">
            <div className="col-span-2">
              <Select
                placeholder={"Select valuation method"}
                value={params?.valuation_method || null}
                className={"form-field_input_3"}
                onSelect={(e: any) => {
                  handleFilter(e, "valuation_method");
                }}
                options={[
                  { value: "cost", label: "Cost" },
                  { value: "retail", label: "Retails" },
                ]}
              />
            </div>
            <div className="col-span-2">
              <Select
                placeholder={"Select location type"}
                value={params?.location_type || null}
                className={"form-field_input_3"}
                onSelect={(e: any) => {
                  handleFilter(e, "location_type");
                }}
                options={[
                  { value: "sales", label: "Sales" },
                  { value: "bulk", label: "Bulk" },
                ]}
              />
            </div>
            <div className="col-span-3">
              <AutoComplete
                options={options}
                onSearch={handleSearch}
                onSelect={(value, option) => {
                  handleFilter(value, "product_id");
                  setSelectedProductName(option.name ?? "");
                }}
                onChange={(value) => {
                  setSelectedProductName(value);
                  handleFilter(value, "product_id");
                  if (!value) {
                    setOptions([]);
                  }
                }}
                value={selectedProductName || params.product_id}
                notFoundContent={isLoading ? <Spin size="small" /> : null}
                allowClear
                style={{ width: "100%", height: "48px" }}
              >
                <input
                  type="text"
                  className="form-field_input_2"
                  placeholder="Search product"
                />
              </AutoComplete>
            </div>
            <div className="col-span-2">
              <Button
                css={"w-auto !rounded-[6px]"}
                state={ButtonState.PRIMARY}
                text={isNewSet ? "Apply" : "Applied"}
                type={"button"}
                disabled={!isNewSet}
                onClick={handleApply}
              />
            </div>
          </div>
        </div>
        <div className="col-span-2 flex justify-end">
          <button
            className="text-sm-regular report-export-btn"
            onClick={handleExport}
          >
            <QuicstockUploadDoc />
            Export
          </button>
        </div>
      </div>
      <div className="w-full bg-white report-table pb-4 overflow-hidden mt-10">
        <Table
          loading={isReportLoading}
          columns={columns}
          dataSource={data as any}
          size={"small"}
          className={"d-table"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: reports?.total_count,
            pageSize: defaultPageSize,
            showTotal: (total) =>
              paginationFooter(paginationData, reports?.total_count, total),
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender: (_, type, originalElement) =>
              itemRender(
                _,
                type,
                originalElement,
                reports?.total_count,
                defaultPageSize
              ),
          }}
        />
      </div>
    </>
  );
};

export { StockValueReportTable };
