/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react";
import "../../styles.scss";
import { IStockValueReportPreference } from "../../model";
import Button from "../../../../components/Button";
import { ButtonState } from "../../../../components/enum";
import { AutoComplete, Form, Select, Spin } from "antd";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../../components/Input/FIeldContainer";
import FieldName from "../../../../components/FieldName";
import { ApiDropdownModel } from "../../../../model/base.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { debounce } from "lodash";
import { SearchProducts } from "../../../../redux/actions/products.action";
import { AppDispatch } from "../../../../redux/store";

const StockValueReportPreference: FC<IStockValueReportPreference> = ({
  cancel,
  generate,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const values = { valuation_method: "", location_type: "", product_id: "" };
  const [form] = Form.useForm();
  const [selectedProductName, setSelectedProductName] = useState("");
  const [options, setOptions] = useState<ApiDropdownModel[]>([]);
  const isLoading = useSelector(
    (state: RootState) => state.products.isProductActionLoading
  );
  const productSearchResult = useSelector(
    (state: RootState) => state.products.productSearchResult
  );

  const handleOnSubmit = (values: any, { resetForm }: any) => {
    generate(values);
  };

  const debouncedSearch = debounce(
    async (value, setOptions, dispatch, setSelectedProductName) => {
      if (value) {
        dispatch(SearchProducts(value));
      } else {
        setOptions([]);
        setSelectedProductName("");
      }
    },
    300
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value, setOptions, dispatch, setSelectedProductName);
    },
    [setOptions, dispatch, setSelectedProductName]
  );

  useEffect(() => {
    if (productSearchResult && productSearchResult.length) {
      const suggestions: ApiDropdownModel[] | any[] = productSearchResult.map(
        (product) => ({
          value: product.id,
          label: product.name,
          name: product.name,
        })
      );
      setOptions(suggestions);
    }
  }, [productSearchResult]);

  return (
    <Formik
      className="w-full"
      initialValues={values}
      onSubmit={handleOnSubmit}
      enableReinitialize={true}
      validationSchema={validator}
    >
      {({ values, errors, touched, handleSubmit, handleBlur }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className={`w-full drawer-content-height-no-step`}>
            <div className={"text-left text-sm-regular my-6 font-medium"}>
              Stock value report
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Valuation method"} required />
                <Form.Item
                  name={"valuation_method"}
                  help={
                    touched.valuation_method && errors.valuation_method
                      ? errors.valuation_method
                      : ""
                  }
                  validateStatus={
                    touched.valuation_method && errors.valuation_method
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Select
                        placeholder={"Select valuation method"}
                        value={values?.valuation_method || null}
                        onBlur={handleBlur}
                        className={"form-field_input_3"}
                        onChange={(e: any) => {
                          setFieldValue("valuation_method", e);
                        }}
                        options={[
                          { value: "cost", label: "Cost" },
                          { value: "retail", label: "Retails" },
                        ]}
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Location type"} />
                <Form.Item
                  name={"location_type"}
                  help={
                    touched.location_type && errors.location_type
                      ? errors.location_type
                      : ""
                  }
                  validateStatus={
                    touched.location_type && errors.location_type
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <Select
                        placeholder={"Select location type"}
                        value={values?.location_type || null}
                        onBlur={handleBlur}
                        className={"form-field_input_3"}
                        onChange={(e: any) => {
                          setFieldValue("location_type", e);
                        }}
                        options={[
                          { value: "sales", label: "Sales" },
                          { value: "bulk", label: "Bulk" },
                        ]}
                      />
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
            <div className={"mb-5"}>
              <FieldContainer iconPlacementCss={"iconCss"}>
                <FieldName name={"Product"} />
                <Form.Item
                  name={"product_id"}
                  help={
                    touched.product_id && errors.product_id
                      ? errors.product_id
                      : ""
                  }
                  validateStatus={
                    touched.product_id && errors.product_id
                      ? "error"
                      : "success"
                  }
                >
                  <Field>
                    {({ form: { setFieldValue } }: any) => (
                      <AutoComplete
                        options={options}
                        onSearch={handleSearch}
                        onSelect={(value, option) => {
                          setFieldValue("product_id", value);
                          setSelectedProductName(option.name ?? "");
                        }}
                        onChange={(value) => {
                          setSelectedProductName(value);
                          setFieldValue("product_id", "");
                          if (!value) {
                            setOptions([]);
                          }
                        }}
                        value={selectedProductName || values.product_id}
                        notFoundContent={
                          isLoading ? <Spin size="small" /> : null
                        }
                        allowClear
                        style={{ width: "100%", height: "48px" }}
                      >
                        <input
                          type="text"
                          className="form-field_input_2"
                          placeholder="Search product"
                        />
                      </AutoComplete>
                    )}
                  </Field>
                </Form.Item>
              </FieldContainer>
            </div>
          </div>
          <div className="w-full left-0 right-0 bottom-0 bg-white">
            <div className="mb-6 border-[0.5px] border-[#EFEFEF]"></div>
            <div className={"grid sm:grid-cols-3 grid-cols-2 gap-x-2"}>
              <div className={"col-1 sm:block hidden"}></div>
              <div className={"col-1"}>
                <Button
                  css={
                    "w-full !bg-white !border-[1px] border-[#F6F6F6] !text-black !font-medium !text-black !font-normal !rounded-[6px]"
                  }
                  state={ButtonState.PRIMARY}
                  text={"Go back"}
                  type={"button"}
                  onClick={cancel}
                />
              </div>
              <div className={"col-1"}>
                <Button
                  css={"w-full !rounded-[6px]"}
                  state={ButtonState.PRIMARY}
                  text={"Generate Report"}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validator = yup.object().shape({
  valuation_method: yup.string().required("Valuation method is required"),
});

export { StockValueReportPreference };
